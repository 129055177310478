








































import Button from "@/components/Button.vue";
import ContentCard from "@/components/ContentCard.vue";
import { useConfirmLinkUserMutation } from "@/graphql/types";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { ContentCard, Button },
  props: {
    email: {
      type: String,
      required: false,
    },
    codeChallenge: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    idpid: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const { mutate, loading, error } = useConfirmLinkUserMutation({});

    function confirm() {
      mutate({
        input: {
          userId: props.id,
          nemLoginId: props.uuid,
          nemLoginIdpId: props.idpid,
          codeChallenge: props.codeChallenge,
        },
      });
    }

    confirm();

    return {
      loading,
      error,
    };
  },
});
